@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&family=Open+Sans&display=swap);
body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Amatic SC", cursive;
}

p,
a {
  font-family: "Open Sans", sans-serif;
}

h2 {
  font-size: 2.5rem !important;
}

